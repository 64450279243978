.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom:5%;
}

.navigation-section {
    text-align: center;
}

.link {
    margin: 3px;
}

.link-styles {
    color:turquoise;
    cursor: pointer;
}

.link-styles:hover {
    color:rgb(19, 173, 150);
}