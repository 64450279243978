.form {
    display: flex;
    flex-direction: row;
    width: 100%;
}   

.innerform {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.wrapper {
    position:absolute;
    width: 600px;
    left:0;
    right: 0;
    top: 0;
    bottom: 0;
    margin:auto;
}

h1 {
    text-align: center;
}

h3 {
    font-size: 10px;
    color: lightgray;
    text-align: center;
}

label {
    width: 250px;
    margin-top:15px;
    font-size: 13px;
}

input {
    margin-top:5px;
    border-width: 2px;
    border-radius: 5px;
    border-color:gray;
    border-style: solid;
    background-color: rgb(38, 38, 38);
    color:white;
    padding: 5px;
}

.submit {
    width: 100%;
    /* height: 25px; */
    margin-top:20px;
    padding: 6px;
    border-color: turquoise;
    background-color: rgb(38, 38, 38);
    border-width: 2px;
    border-radius: 5px;
    color: turquoise;
    cursor: pointer;
    font-size: large;
    font-weight: 700;
    outline: none
}

.submit:active {
    border-style: outset;
    background-color: rgb(44, 44, 44);
}

.message {
    margin-top:20px;
    text-align: center;
}


@media all and (min-width:450px) and (max-width: 700px) {
    .wrapper {
        width: 400px;
    }
}

@media all and (min-width:100px) and (max-width: 449px) {
    .wrapper {
        width: 300px;
    }
}
  